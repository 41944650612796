// Theme: Simple
// =============================================================================
// Main Import
@import "theme-defaults";

// Shared Imports
@import "shared/code-light";

// Properties
:root {
    // App
    // =========================================================================
    // Borders
    --border-radius-s                    : 2px;
    --border-radius-m                    : 4px;
    --border-radius-l                    : 8px;


    // Base
    // =========================================================================
    // Strong
    --strong-font-weight                 : 600;


    // Content
    // =========================================================================
    // Blockquote
    --blockquote-border-color            : var(--theme-color);
    --blockquote-border-radius           : 0 var(--border-radius-m) var(--border-radius-m) 0;
    --blockquote-border-width            : 0 0 0 4px;

    // Code
    --code-inline-background             : var(--mono-tint2);
    --code-theme-background              : var(--mono-tint3);

    // Headings
    --heading-font-weight                : var(--strong-font-weight);
    --heading-h1-font-weight             : 400;
    --heading-h2-font-weight             : 400;
    --heading-h2-border-color            : var(--mono-tint2);
    --heading-h2-border-width            : 0 0 1px 0;
    --heading-h2-margin                  : 2.5rem 0 1.5rem;
    --heading-h2-padding                 : 0 0 1rem 0;

    // Keyboard
    --kbd-border                         : 1px solid var(--mono-tint2);

    // Notice
    --notice-border-radius               : 0 var(--border-radius-m) var(--border-radius-m) 0;
    --notice-border-width                : 0 0 0 4px;
    --notice-padding                     : 1em 1.5em 1em 3em;

    // Notice Pseudo
    --notice-before-border-radius        : 100%;
    --notice-before-font-weight          : bold;
    --notice-before-height               : 1.5em;
    --notice-before-left                 : 0.75em;
    --notice-before-line-height          : 1.5;
    --notice-before-margin               : 0 0.25em 0 0;
    --notice-before-position             : absolute;
    --notice-before-width                : var(--notice-before-height);

    // Notice - Important
    --notice-important-background        : hsl(340, 60%, 96%);
    --notice-important-border-color      : hsl(340, 90%, 45%);

    // Notice - Important Pseudo
    --notice-important-before-background : var(--notice-important-border-color) #{svg-data-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="#fff"><path d="M10 14C10 15.1 9.1 16 8 16 6.9 16 6 15.1 6 14 6 12.9 6.9 12 8 12 9.1 12 10 12.9 10 14Z"/><path d="M10 1.6C10 1.2 9.8 0.9 9.6 0.7 9.2 0.3 8.6 0 8 0 7.4 0 6.8 0.2 6.5 0.6 6.2 0.9 6 1.2 6 1.6 6 1.7 6 1.8 6 1.9L6.8 9.6C6.9 9.9 7 10.1 7.2 10.2 7.4 10.4 7.7 10.5 8 10.5 8.3 10.5 8.6 10.4 8.8 10.3 9 10.1 9.1 9.9 9.2 9.6L10 1.9C10 1.8 10 1.7 10 1.6Z"/></svg>')} center / 0.875em no-repeat;
    --notice-important-before-color      : #fff;
    --notice-important-before-content    : "";

    // Notice - Tip
    --notice-tip-background              : hsl(204, 60%, 96%);
    --notice-tip-border-color            : hsl(204, 90%, 45%);

    // Notice - Tip Psuedo
    --notice-tip-before-background       : var(--notice-tip-border-color) #{svg-data-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="#fff"><path d="M9.1 0C10.2 0 10.7 0.7 10.7 1.6 10.7 2.6 9.8 3.6 8.6 3.6 7.6 3.6 7 3 7 2 7 1.1 7.7 0 9.1 0Z"/><path d="M5.8 16C5 16 4.4 15.5 5 13.2L5.9 9.1C6.1 8.5 6.1 8.2 5.9 8.2 5.7 8.2 4.6 8.6 3.9 9.1L3.5 8.4C5.6 6.6 7.9 5.6 8.9 5.6 9.8 5.6 9.9 6.6 9.5 8.2L8.4 12.5C8.2 13.2 8.3 13.5 8.5 13.5 8.7 13.5 9.6 13.2 10.4 12.5L10.9 13.2C8.9 15.2 6.7 16 5.8 16Z"/></svg>')} center / 0.875em no-repeat;
    --notice-tip-before-color            : #fff;
    --notice-tip-before-content          : "";

    // Tables
    --table-cell-border-color            : var(--mono-tint2);
    --table-cell-border-width            : 1px 0;


    // Cover
    // =========================================================================
    // Background
    --cover-background-color             : hsl(var(--theme-hue), 25%, 60%);
    --cover-background-image             : radial-gradient(ellipse at center 115%, rgba(255, 255, 255, 0.9), transparent);

    // Blockquote (Subtitle)
    --cover-blockquote-color             : var(--strong-color);

    // Heading
    --cover-heading-color                : #fff;
    --cover-heading-font-size-max        : 56; // Unitless px
    --cover-heading-font-size-min        : 34; // Unitless px
    --cover-heading-font-weight          : 200;


    // Navbar
    // =========================================================================
    // Root
    --navbar-root-color--active          : var(--theme-color);

    // Menus
    --navbar-menu-border-radius          : var(--border-radius-m);

    // Menu Root
    --navbar-menu-root-background        : #{chevron-data-uri(down, null, 6px, 1.5, #b3b3b3)} right no-repeat;
    --navbar-menu-root-padding           : 0 18px 0 0;


    // Plugin: Search
    // =========================================================================
    // Input
    --search-input-background-color      : #fff;
    --search-input-background-image      : #{svg-data-uri('<svg height="20px" width="20px" viewBox="0 0 24 24" fill="none" stroke="rgba(0, 0, 0, 0.3)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg"><circle cx="10.5" cy="10.5" r="7.5" vector-effect="non-scaling-stroke"></circle><line x1="21" y1="21" x2="15.8" y2="15.8" vector-effect="non-scaling-stroke"></line></svg>')};
    --search-input-background-position   : 25px center;
    --search-input-border-color          : var(--sidebar-border-color);
    --search-input-border-width          : 1px 0;
    --search-input-margin                : 0 -25px;
    --search-input-padding               : 0.65em 1em 0.65em 50px;
    --search-input-placeholder-color     : rgba(0, 0, 0, 0.4);

    /* Clear Button */
    --search-clear-icon-color1           : rgba(0, 0, 0, 0.3);

    // Results
    --search-result-heading-font-weight  : var(--strong-font-weight);
    --search-result-item-border-color    : var(--sidebar-border-color);
    --search-result-keyword-border-radius: var(--border-radius-s);


    // Sidebar
    // =========================================================================
    --sidebar-background                                : var(--mono-tint3);
    --sidebar-border-color                              : var(--mono-tint2);
    --sidebar-border-width                              : 0 1px 0 0;

    // Name
    --sidebar-name-color                                : var(--theme-color);
    --sidebar-name-font-weight                          : 300;

    // Nav Headings
    --sidebar-nav-strong-border-width                   : 0 0 1px 0;
    --sidebar-nav-strong-font-size                      : smaller;
    --sidebar-nav-strong-margin                         : 2em -25px 0.75em 0;
    --sidebar-nav-strong-padding                        : 0.25em 0 0.75em 0;
    --sidebar-nav-strong-text-transform                 : uppercase;

    // Nav Links
    --sidebar-nav-link-border-color                     : transparent;
    --sidebar-nav-link-border-color--active             : var(--theme-color);
    --sidebar-nav-link-border-width                     : 0 4px 0 0;
    --sidebar-nav-link-color--active                    : var(--theme-color);
    --sidebar-nav-link-margin                           : 0 -25px 0 0;
    --sidebar-nav-link-text-decoration                  : none;
    --sidebar-nav-link-text-decoration--active          : none;
    --sidebar-nav-link-text-decoration--hover           : underline;

    // Nav Links - Pseudo Content
    --sidebar-nav-link-before-content-l3                : '-';

    // Nav Page Links
    --sidebar-nav-pagelink-background-image             : #{chevron-data-uri(right, 7px, null, 1.5, #b3b3b3)};
    --sidebar-nav-pagelink-background-image--active     : #{chevron-data-uri(down, null, 7px, 1.5, #0b87da)};
    --sidebar-nav-pagelink-background-image--collapse   : #{chevron-data-uri(right, 7px, null, 1.5, #0b87da)};
    --sidebar-nav-pagelink-background-image--loaded     : #{chevron-data-uri(down, null, 7px, 1.5, #0b87da)};
    --sidebar-nav-pagelink-background-position          : 3px center;
    --sidebar-nav-pagelink-background-position--active  : left center;
    --sidebar-nav-pagelink-background-position--collapse: var(--sidebar-nav-pagelink-background-position);
    --sidebar-nav-pagelink-background-position--loaded  : var(--sidebar-nav-pagelink-background-position--active);
    --sidebar-nav-pagelink-padding                      : 0.25em 0 0.25em 20px;
    --sidebar-nav-pagelink-transition                   : none;

    // Toggle
    --sidebar-toggle-background                         : var(--sidebar-border-color);
    --sidebar-toggle-border-radius                      : 0 var(--border-radius-s) var(--border-radius-s) 0;
    --sidebar-toggle-width                              : 32px;
}
