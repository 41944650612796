// Plugin: Zoom Image
// https://docsify.js.org/#/plugins?id=zoom-image
//
// Default styles are applied via a dynamically-generated <style> element.
// Injected styles are copied below for reference only.
// =============================================================================
// v4.6.10
.medium-zoom-overlay {
    // position: fixed;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // opacity: 0;
    // transition: opacity 0.3s;
    // will-change: opacity;

    .medium-zoom--open & {
        // opacity: 1;
        // cursor: pointer;
        // cursor: zoom-out;
    }
}

.medium-zoom-image {
    // cursor: pointer;
    // cursor: zoom-in;
    // transition: transform 0.3s;

    &--open {
        // position: relative;
        // z-index: 1;
        // cursor: pointer;
        // cursor: zoom-out;
        // will-change: transform;
    }
}


// Overriding injected styles (above) requires higher specificity:
// - For <style> declaration, add parent element to selector (e.g. body)
// - For inline declarations, add !important to selector
// =============================================================================
.medium-zoom-overlay,
.medium-zoom-image--open {
    z-index: map-get($z-index, overlay) !important;
}

.medium-zoom-overlay {
    background: var(--zoomimage-overlay-background) !important;
}
