/* Content
============================================================================= */
:root {
    --content-max-width                 : 55em;

    /* Blockquote */
    --blockquote-background             : var(--mono-tint3);
    --blockquote-border-color           : ;
    --blockquote-border-style           : solid;
    --blockquote-border-width           : ;
    --blockquote-border-radius          : var(--border-radius-m);
    --blockquote-color                  : ;
    --blockquote-em-font-family         : ;
    --blockquote-em-font-size           : ;
    --blockquote-em-font-style          : ;
    --blockquote-em-font-weight         : ;
    --blockquote-font-family            : ;
    --blockquote-font-size              : ;
    --blockquote-font-style             : ;
    --blockquote-font-weight            : ;
    --blockquote-quotes-close           : ;
    --blockquote-quotes-color           : ;
    --blockquote-quotes-font-family     : ;
    --blockquote-quotes-font-size       : ;
    --blockquote-quotes-open            : ;

    /* Code */
    --code-font-family                  : Inconsolata, Consolas, Menlo, Monaco, "Andale Mono WT", "Andale Mono", "Lucida Console", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
    --code-font-size                    : calc(var(--font-size-m) * 0.95);
    --code-font-weight                  : ;
    --code-tab-size                     : 4;

    --code-block-border-radius          : var(--border-radius-m);
    --code-block-line-height            : var(--base-line-height);
    --code-block-margin                 : 1em 0;
    --code-block-padding                : 1.75em 1.5em 1.5em 1.5em;

    --code-inline-background            : var(--code-theme-background);
    --code-inline-border-radius         : var(--border-radius-s);
    --code-inline-color                 : var(--code-theme-text);
    --code-inline-margin                : 0 0.15em;
    --code-inline-padding               : 0.125em 0.4em;

    --code-theme-background             : var(--mono-tint3);
    --code-theme-comment                : ;
    --code-theme-function               : ;
    --code-theme-keyword                : ;
    --code-theme-operator               : ;
    --code-theme-punctuation            : ;
    --code-theme-selection              : ;
    --code-theme-selector               : ;
    --code-theme-tag                    : ;
    --code-theme-text                   : ;
    --code-theme-variable               : ;

    /* Headings */
    --heading-color                     : var(--mono-shade3);
    --heading-font-family               : ;
    --heading-font-weight               : ;
    --heading-margin                    : 2.5rem 0 0;
    --heading-padding                   : ;
    --heading-h1-border-color           : ;
    --heading-h1-border-style           : solid;
    --heading-h1-border-width           : ;
    --heading-h1-color                  : ;
    --heading-h1-font-family            : ;
    --heading-h1-font-size              : var(--font-size-xxl);
    --heading-h1-font-weight            : ;
    --heading-h1-margin                 : ;
    --heading-h1-padding                : ;
    --heading-h2-border-color           : ;
    --heading-h2-border-style           : solid;
    --heading-h2-border-width           : ;
    --heading-h2-color                  : ;
    --heading-h2-font-family            : ;
    --heading-h2-font-size              : var(--font-size-xl);
    --heading-h2-font-weight            : ;
    --heading-h2-margin                 : ;
    --heading-h2-padding                : ;
    --heading-h3-border-color           : ;
    --heading-h3-border-style           : solid;
    --heading-h3-border-width           : ;
    --heading-h3-color                  : ;
    --heading-h3-font-family            : ;
    --heading-h3-font-size              : var(--font-size-l);
    --heading-h3-font-weight            : ;
    --heading-h3-margin                 : ;
    --heading-h3-padding                : ;
    --heading-h4-border-color           : ;
    --heading-h4-border-style           : solid;
    --heading-h4-border-width           : ;
    --heading-h4-color                  : ;
    --heading-h4-font-family            : ;
    --heading-h4-font-size              : var(--font-size-m);
    --heading-h4-font-weight            : ;
    --heading-h4-margin                 : ;
    --heading-h4-padding                : ;

    /* Keyboard */
    --kbd-background                    : var(--mono-tint3);
    --kbd-border                        : ;
    --kbd-border-radius                 : var(--border-radius-m);
    --kbd-color                         : ;
    --kbd-font-size                     : ;
    --kbd-margin                        : 0 0.3em;
    --kbd-min-width                     : 2.5em;
    --kbd-padding                       : 0.65em 0.5em;

    /* Links */
    --link-border-bottom                : ;
    --link-border-bottom--hover         : ;
    --link-color                        : ;
    --link-color--hover                 : ;
    --link-text-decoration              : underline;
    --link-text-decoration--hover       : ;
    --link-text-decoration-color        : ;
    --link-text-decoration-color--hover : ;

    /* Notice */
    --notice-background                 : var(--mono-tint3);
    --notice-border-color               : ;
    --notice-border-radius              : var(--border-radius-m);
    --notice-border-style               : solid;
    --notice-border-width               : ;
    --notice-color                      : ;
    --notice-font-family                : ;
    --notice-font-weight                : ;
    --notice-padding                    : 1em 1.5em;

    /* Notice - Pseudo */
    --notice-before-background          : ;
    --notice-before-border-radius       : ;
    --notice-before-color               : ;
    --notice-before-content             : ;
    --notice-before-font-family         : ;
    --notice-before-font-size           : ;
    --notice-before-font-weight         : ;
    --notice-before-height              : ;
    --notice-before-left                : ;
    --notice-before-line-height         : ;
    --notice-before-margin              : ;
    --notice-before-padding             : ;
    --notice-before-position            : ;
    --notice-before-top                 : ;
    --notice-before-width               : ;

    /* Notice - Important */
    --notice-important-background       : ;
    --notice-important-border-color     : ;
    --notice-important-border-style     : ;
    --notice-important-border-width     : ;
    --notice-important-color            : ;

    /* Notice - Important Pseudo */
    --notice-important-before-background: ;
    --notice-important-before-color     : ;
    --notice-important-before-content   : ;

    /* Notice - Tip */
    --notice-tip-background             : ;
    --notice-tip-border-color           : ;
    --notice-tip-border-style           : ;
    --notice-tip-border-width           : ;
    --notice-tip-color                  : ;

    /* Notice - Tip Pseudo */
    --notice-tip-before-background      : ;
    --notice-tip-before-color           : ;
    --notice-tip-before-content         : ;

    /* Tables */
    --table-body-border-color           : ;
    --table-body-border-width           : ;
    --table-cell-border-color           : ;
    --table-cell-border-width           : ;
    --table-cell-padding                : 0.75em 0.5em;
    --table-head-background             : ;
    --table-head-border-color           : var(--table-cell-border-color);
    --table-head-border-width           : ;
    --table-head-font-weight            : var(--strong-font-weight);
    --table-row-even-background         : ;
    --table-row-odd-background          : var(--mono-tint3);
}
