// Plugin: Copy Code
// https://github.com/jperasmus/docsify-copy-code
//
// Default styles are applied via an external stylesheet.
// External styles are copied below for reference only.
// https://unpkg.com/docsify-copy-code/styles.css
// =============================================================================
// v1.1.0
.docsify-copy-code-button {
    // background: #ccc;
    // color: #fff;
    // position: absolute;
    // top: 0;
    // right: 0;
    // outline: 0;
    // border: 0;
    // border-radius: 0;
    // cursor: pointer;
    // z-index: 1;
    // padding: 10px;
    // transition: all 0.25s ease;
    // opacity: 0;

    &::after {
        // content: "Copied!";
        // position: absolute;
        // right: 100%;
        // top: 0;
        // background: #ccc;
        // color: #fff;
        // padding: 5px;
        // margin: 5px 10px 0;
        // font-size: 11px;
        // z-index: 0;
        // transition: all 0.25s ease;
        // transform: translateX(120%) scale(0);
        // border-radius: 3px;
    }

    &.success::after {
        // transform: translateX(0) scale(1);
    }

    pre[v-pre]:hover & {
        // opacity: 1;
    }
}


// Overriding injected styles (above) requires higher specificity:
// - For <style> declaration, add parent element to selector (e.g. body)
// - For inline declarations, add !important to selector
// =============================================================================
body {
    .docsify-copy-code-button,
    .docsify-copy-code-button::after {
        background: var(--copycode-background);
        color: var(--copycode-color);
    }
}
